<template>
  <div id="clanak">
    
    <div id=image-holder2 :style="'background-image: url(\''+clanak.slika+'\''+')'">
      <h1>{{clanak.naslov}}</h1>
      
      <p id="datum">{{clanak.datum}}</p>
    </div>
    <div id="clanak-body">
      <section id="clanak-sadrzaj">
        <span v-html="clanak.tekst">
        </span>
      </section>
    </div>
  </div>
</template>

<script>
import axios from '@/axios';

export default {
  name:'Clanak',
  data: function(){
    return(
      {
        clanak:{},
        id: this.$route.params.id
      }
    );
  },
  methods:{  
    getData(){
      let self=this;
      axios.get('clanak.php?id='+this.id).then(function (response) {
        console.log("OK! ", response, response.data);
        self.clanak=response.data;
        self.clanak.datum=new Date(self.clanak.datum).toLocaleDateString("sr-Latn-RS");
        if(self.clanak.slika=='')
          self.clanak.slika=require("@/assets/novosti.jpg");
      })
      .catch(function (error) {
        console.log("ERROR",error.response);
      });
    }
  },
  mounted(){
    this.getData();
  }
}
</script>

<style scoped>

#datum{
  color:white;
  font-size:25px;
}

#image-holder2{
  display:flex;
  flex-direction: column;
  /* opacity:0.9;  */
  background-image: url("../assets/novosti.jpg");
  background-position: center;
  background-size:cover;
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
}

#image-holder2 h1{
  font-size:60px;
}

#clanak-body{
  background-color: #dcdcdc;
  padding:40px 5% 100px 5%;
  min-height: 100vh;
}

#clanak-sadrzaj{
  
  margin:20px;
  padding: 40px;
  padding-left:40px;
  /* border-color: #f5871eff; */
  border-radius: 20px;
  border-width: 0.5px;
  -webkit-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.75);
  /* width:100%; */
  background-color: white;  
  text-align: left;
  font-size:1.2rem;
}

@media screen and (max-width: 600px) {
  
  #image-holder2 h1{
    font-size:35px;
  }
  #clanak-sadrzaj{
    font-size:15px;
  }

}

</style>